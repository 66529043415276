/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { createRef, useState } from "react";
import ModalHeader from "./ModalHeader";
import { Icon } from "@iconify/react/dist/iconify.js";
import ModalFooter from "./ModalFooter";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import SpinnerMUI from "../component/SpinnerMUI";
import { createFileName, useScreenshot } from "use-react-screenshot";
import QRCode from "react-qr-code";
import { upiWeb } from "../iconsImports";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  p: 2,
  height: "max-content",
  
  // overflowY: "scroll",
};

const AdminCreateQr = ({ row, refresh }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vaData, setVaData] = useState({ vaRes: {}, vaCreated: false });

  const vqr = row?.vqr;
  const selfqrValue =
    vqr
      ? `upi://pay?pa=${vqr}&pn=${row?.establishment}&cu=INR`
      : "if you want to use our QR";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirmClose = () => setConfirmOpen(false);

  const genrateQr = () => {
    postJsonData(
      ApiEndpoints.CREATE_QR,
      { id: row.id },
      setLoading,
      (res) => {
        setVaData({ vaRes: res?.data?.data, vaCreated: true });
        okSuccessToast("QR Account created");
        if (refresh) refresh();
        setConfirmOpen(false);
      },
      (err) => {
        apiErrorToast(err);
        setVaData({ vaRes: {}, vaCreated: false });
      }
    );
  };

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const download = (
    image,
    { name = "Qr Acct", extension = "png" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const ref = createRef(null);
  const downloadScreenshot = () => {
    takeScreenshot(ref.current).then(download);
  };

  return (
    <>
      {vqr ? (
        <Tooltip title="View QR Code" placement="bottom">
          <IconButton onClick={handleOpen} className="shadow-effect">
            <Icon
               icon="mdi:qrcode-plus"
              style={{ fontSize: "25px", color: "blue" }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Create QR " placement="bottom">
          <IconButton
            onClick={() => setConfirmOpen(true)}
            className="shadow-effect"
          >
            {loading ? (
              <SpinnerMUI loading={loading} size={22} />
            ) : (
              <Icon
              icon="mdi:qrcode-plus"
                style={{ fontSize: "25px", color: "red" }}
              />
            )}
          </IconButton>
        </Tooltip>
      )}

      {/* QR Code Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="sm_modal">
          <ModalHeader title="QR Code" handleClose={handleClose} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            <QRCode value={selfqrValue} size={156} />
          </div>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "11px",
              fontWeight: "600",
              color: "#676970",
              p: 2.2,
              pb: 0,
            }}
          >
            *Note: This Payment will be added to W2
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            <img src={upiWeb} alt="upi apps" />
          </div>
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal open={confirmOpen} onClose={handleConfirmClose}>
        <Box sx={style}>
          <ModalHeader title=" QR Code" handleClose={handleConfirmClose} />
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "28px",
              mt: 2,
           
            }}
          >
           Create Qr Code
          </Typography>
          <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  mt: 2,
                  color: "#5E548E",
                }}
              >
                { row.name}
              </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 2,
            }}
          >
              <ModalFooter btn="create" onClick={genrateQr} />
            {/* <Button
              variant="contained"
              color="primary"
              onClick={genrateQr}
              disabled={loading}
            >
              {loading ? "Processing..." : "Proceed"}
            </Button> */}
            
          </Box>
        </Box>
      </Modal>
    </>
  );
};


export default AdminCreateQr;
