import biggpayLogo from "./assets/logo.png";
import biggpayLogo_sm from "./assets/impsguru.ico";
import airIndia from "./assets_travel/images/icons/AI.png";
import closeicon from "./assets_travel/images/icons/close1.png";
import successVdo from "./assets/video/checkmark.mp4";
import failedVdo from "./assets/video/exclaimation.mp4";

//
import bbps from "./assets/bbps.png";
import login_illustration from "./assets/loginIllustration.png";
import landingPageIllustration from "./assets/landing-page-assets/landingPageillustration.webp";
import contact from "./assets/sidebar_icons/contactUs.png";
import dash from "./assets/sidebar_icons/dashboard.svg";
import express from "./assets/sidebar_icons/ExpressTransfer.png";
import money from "./assets/sidebar_icons/moneyTransfer.png";
import rechargeicon from "./assets/sidebar_icons/recharge.png";
import txn from "./assets/sidebar_icons/transaction.png";
import upiTrans from "./assets/sidebar_icons/upiTransfer.png";
import hotel from "./assets/sidebar_icons/hotelbooking.svg";
import bus from "./assets/sidebar_icons/busbooking.svg";
import flight from "./assets/sidebar_icons/flightbooking.svg";
import footerbg from "./assets/footer-bg.png";
import userAvatar from "./assets/user.webp";
import i from "./assets/info.png";
import rupeeLogo from "./assets/rupee.png";
import upiImg from "./assets/upi.svg";
import finger_printsvg from "./assets/svg/fingerprint_new.svg";
import transaction_black from "./assets/transaction18.png";
import transaction_white from "./assets/transaction_white.png";
import successGif from "./assets/success.json";
import successGif2 from "./assets/successDone.json";
import chatBox from "./assets/chat.png";

import panda_illustration from "./assets/Panda.svg";
import paytm from "./assets/paytm.svg";
import about_us from "./assets/aboutus.webp";
import empty_table from "./assets/Panda.svg";
import not_found_404 from "./assets/404_not_found.jpg";
import unauthorizedNew from "./assets/401_Error_Unauthorized.png";
import no_data_gif from "./assets/no_data.gif";
import wave_bg from "./assets/wave-haikei.png";
import team from "./assets/teamwork.jpg";

//bbps icons import
import broadband_svg from "./assets/svg/broadband.svg";
import broadband_invert_svg from "./assets/svg-invert/broadband.svg";
import card from "./assets/svg/credit_card.svg";
import card_invert from "./assets/svg-invert/credit_card.svg";
import car_svg from "./assets/svg/car.svg";
import car_invert_svg from "./assets/svg-invert/car_white.svg";
import clubs_svg from "./assets/svg/clubs.svg";
import clubs_svg_invert from "./assets/svg-invert/clubs.svg";
import dth_svg from "./assets/svg/dth.svg";
import dth_invert_svg from "./assets/svg-invert/dth.svg";
import electricity_invert_svg from "./assets/svg-invert/electricity.svg";
import fasttag_svg from "./assets/svg/toll_tax.svg";
import fasttag_invert from "./assets/svg-invert/toll.svg";
import gas_svg from "./assets/svg/gas.svg";
import gas_invert_svg from "./assets/svg-invert/gas.svg";
import health_svg from "./assets/svg/health.svg";
import health_invert_svg from "./assets/svg-invert/health_white.svg";
import hospital_svg from "./assets/svg/hospital.svg";
import hospital_svg_invert from "./assets/svg-invert/hospital.svg";
import housing_society_svg from "./assets/svg/housing_society.svg";
import housing_society_svg_invert from "./assets/svg-invert/housing_society.svg";
import piggy_bank_svg from "./assets/svg/piggy_bank.svg";
import recurring_deposit_svg_invert from "./assets/svg-invert/recurring_deposits.svg";
import recharge_white_svg from "./assets/svg/recharge_white.svg";
import recharge_white_invert_svg from "./assets/svg-invert/recharge_white.svg";
import subscriptions_svg from "./assets/svg/subscriptions.svg";
import subscriptions_svg_invert from "./assets/svg-invert/subscriptions.svg";
import taxes_svg from "./assets/svg/taxes.svg";
import taxes_svg_invert from "./assets/svg-invert/taxes.svg";
import water_drop_svg from "./assets/svg/water_drop.svg";
import water_drop_invert_svg from "./assets/svg-invert/water_drop.svg";
import mobile_recharge from "./assets/mobileRec.png";
import m_recharge from "./assets/recharge.webp";
import irctc from "./assets/irctc.png";
import qr_img from "./assets/qrCode.webp";
import aadharAtm from "./assets/atm.webp";
import money_t from "./assets/moneyT.png";
import join_us from "./assets/joinus.webp";
import join_us_img from "./assets/joinus2.webp";
import contact_img from "./assets/contactus.webp";
import certificateT from "./assets/certificatetop.png";
import certificateB from "./assets/certificatebottom.png";
import playstoreIcon from "../src/assets/playstore.png";
import appStoreIcon from "../src/assets/appstore.png";
import we_are from "./assets/whowe.jpg";
import yellowinfo from "./assets/exclamation.png";
import redinfo from "./assets/siren.png";
import blueinfo from "./assets/info.svg";
import api_img from "./assets/api.webp";
import excelImage from "./assets/excel.png";
import cable from "./assets/svg/Cabletv-svg-trans.svg";
import cableInverted from "./assets/svg-invert/Cabletv_white-svg-trans.svg";
import rupay from "./assets/rupay.png";
import master from "./assets/mastercard.png";
import visa from "./assets/visa.png";
import mo_vison from "./assets/illus14.png";
import mo_mission from "./assets/illus12.png";
import mo_values from "./assets/illus151.png";
import location from "./assets/svg/location.png";
import iphone from "./assets/svg/iphone.png";
import message from "./assets/svg/message.png";
import globe from "./assets/svg/globe.png";
import stamp from "./assets/impsguru_stamp.png";
import certificate from "./assets/svg/certificate_icon.png";
import prabhu from "./assets/prabhuMT.jpg";
import kycverification from "./assets/kyc_verification.webp";
import indNepal from "./assets/indo_nepal.webp";

import aepsAuth from "./assets/aepsAuth.webp";
import processing from "./assets/processing.png";
import creditReqImg from "./assets/just_icons/creditReq.svg";
import complaintsImg from "./assets/just_icons/complaints.svg";

// side nav images

import linkdinIcon from "../src/assets/linkdin.png";
import twitterIcon from "../src/assets/twitter.png";
import facebookIcon from "../src/assets/facebook.png";
import instaIcon from "../src/assets/insta.png";
import aboutUsNew from "../src/assets/aboutUs.png";

import serviceDN from "../src/assets/serviceDown.jpg";
import unauth from "../src/assets/unauthorized.jpg";
import slide_one from "../src/assets/slide1.webp";
import slide_two from "../src/assets/slide2.webp";
// new nav
import ledgre from "../src/assets/sidebar_icons/accountledger.svg";
import purchase from "../src/assets/sidebar_icons/mypurchase.svg";
import khata from "../src/assets/sidebar_icons/khatabook.svg";
import complaints from "../src/assets/sidebar_icons/mycomplaints.svg";
import credit from "../src/assets/sidebar_icons/creditrequest.svg";
import tran from "../src/assets/sidebar_icons/mytransactions.svg";
import mRec from "../src/assets/sidebar_icons/mobile.svg";
import dthI from "../src/assets/sidebar_icons/DTH.svg";
import elec from "../src/assets/sidebar_icons/electricity.svg";
import ccard from "../src/assets/sidebar_icons/creditcard.svg";
import gasI from "../src/assets/sidebar_icons/gas.svg";
import bb from "../src/assets/sidebar_icons/broad band.svg";
import aeps from "../src/assets/sidebar_icons/aeps.svg";
import mtrans from "../src/assets/sidebar_icons/money transfer.svg";
import etrans from "../src/assets/sidebar_icons/express transfer.svg";
import strans from "../src/assets/sidebar_icons/super transfer.svg";
import ntrans from "../src/assets/sidebar_icons/nepal transfer.svg";
import utrans from "../src/assets/sidebar_icons/UPI transfer.svg";
import CMS from "../src/assets/sidebar_icons/CMS.svg";
import ftag from "../src/assets/sidebar_icons/fast tag.svg";
import gcyliner from "../src/assets/sidebar_icons/gas cylinder.svg";
import mpost from "../src/assets/sidebar_icons/mobile postpaid.svg";
import mpre from "../src/assets/sidebar_icons/mobile prepaid.svg";
import gpipe from "../src/assets/sidebar_icons/piped gas.svg";
import allbbps from "../src/assets/sidebar_icons/allbbps.svg"; ///
import lic from "../src/assets/sidebar_icons/LIC.svg"; ///
import land from "../src/assets/sidebar_icons/landline.svg"; ///
import water from "../src/assets/sidebar_icons/water.svg"; ///
import electricity from "./assets/svg/electricity.svg";
import drawer from "../src/assets/sidebar_icons/drawer.png";
import defaultL from "../src/assets/defaultLayout.png";
import sbi from "../src/assets/just_icons/bank_img.png";
import cms from "./assets/just_icons/cms2.png";
import aepsGuidelines from "./assets/aeps-guidelines.png";
import aepsGuidelinesNew from "./assets/aeps-guidelines-new.png";
import creditReqGuidelines from "./assets/credit-request-disclaimer.png";
import irctcAuth from "./assets/irctc-auth.png";
import irctcTravel from "./assets_travel/images/irctc.jpg";
export const Logo = biggpayLogo;
export const smLogo = biggpayLogo_sm;

//bbps export
export const bbpsPng = bbps;

// bbps icons . . .
export const electricity_svg = electricity;
export const electricity_invert = electricity_invert_svg;
export const broadband = broadband_svg;
export const broadband_invert = broadband_invert_svg;
export const car = car_svg;
export const carInvert = car_invert_svg;
export const clulbSvg = clubs_svg;
export const clulbSvgInvert = clubs_svg_invert;
export const dth = dth_svg;
export const dth_invert = dth_invert_svg;
export const fastTag = fasttag_svg;
export const fastTag_invert = fasttag_invert;
export const gas = gas_svg;
export const gas_invert = gas_invert_svg;
export const health = health_svg;
export const healthInvert = health_invert_svg;
export const hospitalSvg = hospital_svg;
export const hospitalSvgInvert = hospital_svg_invert;
export const housingSvg = housing_society_svg;
export const housingSvgInvert = housing_society_svg_invert;
export const piggyBankSvg = piggy_bank_svg;
export const rDepositSvgInvert = recurring_deposit_svg_invert;
export const recharge_white = recharge_white_svg;
export const recharge_white_invert = recharge_white_invert_svg;
export const subsciptionSvg = subscriptions_svg;
export const subsciptionSvgInvert = subscriptions_svg_invert;
export const taxSvg = taxes_svg;
export const taxSvgInvert = taxes_svg_invert;
export const water_drop = water_drop_svg;
export const water_drop_invert = water_drop_invert_svg;
export const credit_card = card;
export const credit_card_invert = card_invert;

//my imports
export const irctcAuthImg = irctcAuth;
export const paytm_img = paytm;
export const success_gif = successGif;
export const success_gif2 = successGif2;
export const transactionBlack = transaction_black;
export const transactionWhite = transaction_white;
export const upiWeb = upiImg;
export const rupee = rupeeLogo;
export const info = i;
export const userAvt = userAvatar;
export const footerBg = footerbg;
export const contactus = contact;
export const dashboard = dash;
export const expressTransfer = express;
export const moneyTransfer = money;
export const recharge = rechargeicon;
export const transaction = txn;
export const upi = upiTrans;
export const hotelBooking = hotel;
export const flightBooking = flight;
export const busBooking = bus;

export const lp_illustration = landingPageIllustration;
export const lg_illustration = login_illustration;
export const panda = panda_illustration;
export const aboutUs = about_us;
export const mobile = mobile_recharge;
export const mobileR_img = m_recharge;
export const irctc_img = irctc;
export const qrCode_img = qr_img;
export const aadharAtm_img = aadharAtm;
export const mt = money_t;
export const emptyTable = empty_table;
export const notFound404 = not_found_404;
export const unauthorized401 = unauthorizedNew;
export const noDataFoundGif = no_data_gif;
export const waveBg = wave_bg;
export const joinus = join_us;

export const joinus2 = join_us_img;
export const contact_us = contact_img;
export const who_we_are = we_are;
export const teamWork = team;

export const yellowNoti = yellowinfo;
export const redNoti = redinfo;
export const blueNoti = blueinfo;
export const api = api_img;
export const excelIcon = excelImage;
export const CableIcon = cable;
export const CableInv = cableInverted;
export const playstore = playstoreIcon;
export const appstore = appStoreIcon;
export const linkdin = linkdinIcon;
export const twitter = twitterIcon;
export const facebook = facebookIcon;
export const insta = instaIcon;
export const rupayimg = rupay;
export const visaimg = visa;
export const masterimg = master;
export const ModrAboutus = aboutUsNew;

export const serviceDown = serviceDN;
export const unauthorized = unauth;

export const chat = chatBox;

export const moVision = mo_vison;
export const moMisson = mo_mission;
export const moValues = mo_values;

export const locIcon = location;
export const messIcon = message;
export const iphoneIcon = iphone;
export const globeIcon = globe;
export const impsguruStamp = stamp;
export const certificateIcon = certificate;
export const prabhuMoneyTransfer = prabhu;
export const kycVImg = kycverification;
export const indoNepal = indNepal;

export const slider1 = slide_one;
export const slider2 = slide_two;

export const account_ledgre = ledgre;
export const transactions = tran;
export const creditReq = credit;
export const creditReqBadgeIcon = creditReqImg;
export const complaintsBadgeIcon = complaintsImg;
export const khataBook = khata;
export const myComplaints = complaints;
export const myPurchase = purchase;
export const topDrawer = drawer;
export const defaultLayout = defaultL;
export const sbiBank = sbi;

export const cTop = certificateT;
export const cBottom = certificateB;
export const aepsAuthImg = aepsAuth;
export const processingImg = processing;

export const mrecIcon = mRec;
export const dthIcon = dthI;
export const elecIcon = elec;
export const cardIcon = ccard;
export const gasIcon = gasI;
export const bbIcon = bb;
export const aepsIcon = aeps;
export const mtIcon = mtrans;
export const etIcon = etrans;
export const stIcon = strans;
export const ntIcon = ntrans;
export const utIcon = utrans;
export const CMSIcon = CMS;
export const Electricity = elec;
export const FASTag = ftag;
export const Cylinder = gcyliner;
export const Postpaid = mpost;
export const Prepaid = mpre;
export const Gas = gpipe;
export const BBPS = allbbps;
export const licIcon = lic;
export const landIcon = land;
export const waterIcon = water;
export const AirIndia = airIndia;
export const closeIcon = closeicon;
export const success_vdo = successVdo;
export const failed_vdo = failedVdo;
export const fingerSvg = finger_printsvg;
export const cmsIcon = cms;
export const aepsGuidelinesImg = aepsGuidelines;
export const aepsGuidelinesNewImg = aepsGuidelinesNew;
export const creditReqGuidelinesImg = creditReqGuidelines;
export const irctcTravelImage = irctcTravel;
